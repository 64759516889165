import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import LinesEllipsis from 'react-lines-ellipsis';
import PlayIcon from '../../assets/img/player/play-btn.svg';
import { useHistory } from 'react-router-dom';
import { equel } from '../../utils';

const PodcastList = (props) => {
  const history = useHistory();
  const {
    language,
    mainTitle,
    title,
    description,
    poster,
    author,
    date,
    duration,
    mp3,
    episodeType,
  } = props;

  const newMainTitle = episodeType == 'bonus' ? 'Webinar' : mainTitle;

  const lang = localStorage.getItem('language');
  const en = lang === 'en';
  return (
    <div
      className="podcast-list-item"
      title={title}
      onClick={() => history.push(`?lang=${language}&playing=${equel(mp3)}`)}
    >
      <time>{moment(date).format('DD.MM.YYYY')}</time>
      <p className="title">{title}</p>
      <div className="description">
        <LinesEllipsis
          text={description}
          maxLine="3"
          ellipsis=" [...]"
          trimRight={false}
          basedOn="letters"
        />
      </div>
      <div
        className="bottom"
        aria-label={en ? 'Click to listen' : 'Dinlemek için tıkla'}
      >
        <p>{newMainTitle}</p>
        <div className="play" aria-labelledby="Dinle">
          <span>{en ? 'Listen' : 'Dinle'}</span>
          <img src={PlayIcon} alt="play" />
        </div>
      </div>
    </div>
  );
};

PodcastList.propTypes = {
  language: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  poster: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  duration: PropTypes.string,
  mp3: PropTypes.string,
  mainTitle: PropTypes.string,
  episodeType: PropTypes.string,
};

export default PodcastList;
