import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';

const BASE_URL = 'https://feeds.megaphone.fm/esinhukuk';

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const equel = (mp3) => mp3.split('.fm/')[1].replace('.mp3', '');

export const onCalculateCurrentTime = (p) => {
  const duration = moment.duration(p, 'seconds');
  const h = duration.hours();
  const m = duration.minutes();
  const s = duration.seconds();
  const hour = h < 10 ? '0' + h : h;
  const min = m < 10 ? '0' + m : m;
  const sec = s < 10 ? '0' + s : s;
  return `${hour}:${min}:${sec}`;
};

export const getTagName = (item, name) => item.getElementsByTagName(name)[0];

export const getData = async (language) => {
  const url = `${BASE_URL}${language === 'tr' ? 'tr' : ''}`;
  const res = await axios.get(url);
  const data = new window.DOMParser().parseFromString(res.data, 'text/xml');
  const channel = data.querySelectorAll('channel')[0];
  return { channel, data: mapData(channel) };
};

const mapData = (channel) => {
  const items = channel.querySelectorAll('item');
  const array = [];
  items.forEach((item) => {
    const mainTitle = channel.querySelector('title').textContent;
    const title = item.querySelector('title').textContent;
    const description = item.querySelector('description').textContent;
    const poster = getTagName(item, 'itunes:image')?.getAttribute('href');
    const author = getTagName(item, 'itunes:author')?.textContent;
    const date = item.querySelector('pubDate').textContent;
    const duration = item.querySelector('duration').textContent;
    const mp3 = getTagName(item, 'enclosure')?.getAttribute('url');
    const episodeType = item.querySelector('episodeType').textContent;
    array.push({
      mainTitle,
      title,
      description,
      poster,
      author,
      date,
      duration,
      mp3,
      episodeType,
    });
  });
  if (items.length == array.length) {
    return array;
  }
};

const convertString = (text) => {
  if (text !== '') {
    return `${text}`
      .toString()
      .toUpperCase()
      .replace(/İ/g, 'I')
      .replace(/Ğ/g, 'G')
      .replace(/Ü/g, 'U')
      .replace(/Ş/g, 'S')
      .replace(/Ö/g, 'O')
      .replace(/Ç/g, 'C');
  }
};

export const onSearch = ({ data, searchBy = [], text, uniqueKey = searchBy[0] }) => {
  let wholeData = [];
  for (let i = 0; i < searchBy.length; i++) {
    const filteredData = data.filter((document) =>
      convertString(document[searchBy[i]])?.includes(convertString(text))
    );
    wholeData = wholeData.concat(filteredData);
  }
  wholeData = _.uniqBy(wholeData, uniqueKey);
  return wholeData;
};

export const copy = () => {
  toast(`Kopyalandı.`, { type: 'success', autoClose: 1000 });
};
