import React, { Component } from 'react';
import './styles.scss';
import PodcastList from '../../components/PodcastList';
import Filter from '../../components/Filter';
import Player from '../../components/Player';
import Footer from '../../components/Footer';
import { equel, getData, getParameterByName, onSearch } from '../../utils';
import history from '../../services/history';
import DefaultLogo from '../../assets/img/default-logo.png';

class Main extends Component {
  state = {
    language: 'tr',
    logo: '',
    title: '',
    description: '',
    copyright: '',
    data: [],
    filterData: [],
    sortActive: true,
  };

  componentDidMount = () => {
    this.getDatas(localStorage.getItem('language'));
  };

  getDatas = async (language) => {
    localStorage.setItem('language', language);
    const lang = language === 'tr' ? 'en' : 'tr';
    const playing = getParameterByName('playing');
    if (!playing) {
      history.push(`?lang=${language}`);
    }
    const { channel, data } = await getData(language);
    this.setState(
      {
        language: lang,
        logo: channel.querySelector('image').childNodes[1].textContent,
        title: channel.querySelector('title').textContent,
        description: channel.querySelector('description').textContent,
        copyright: channel.querySelector('copyright').textContent,
        data,
        filterData: [],
      },
      this.onSearch
    );
  };

  onSort = () => {
    const { sortActive, filterData, data } = this.state;
    const newData = filterData.length > 0 ? filterData : data;
    const filter = newData.sort((a, b) => {
      if (sortActive) {
        return new Date(b.date) - new Date(a.date);
      } else {
        return new Date(a.date) - new Date(b.date);
      }
    });
    this.setState({ data: filter });
  };

  onSearch = () => {
    const { data } = this.state;
    const search = document.querySelector('#search').getAttribute('value');
    if (search !== '') {
      const newData = onSearch({
        data,
        searchBy: ['mainTitle', 'author', 'title', 'description'],
        text: search,
        uniqueKey: 'mp3',
      });
      this.setState({ filterData: newData });
    } else {
      this.setState({ filterData: [] });
    }
  };

  render() {
    const {
      data,
      filterData,
      logo,
      language,
      title,
      description,
      sortActive,
    } = this.state;
    const search = document.querySelector('#search')?.getAttribute('value');
    const en = language === 'en';
    return (
      <>
        <main>
          <section id="welcome">
            <div className="content">
              <div className="header">
                <a href="https://esin.av.tr" target="_blank" rel="noreferrer">
                  <img src={DefaultLogo} alt="esin-podcast" />
                </a>
                <div
                  className="language"
                  onClick={() => this.getDatas(language)}
                  aria-label={
                    en
                      ? 'Click to change language'
                      : 'Dili değiştirmek için tıklayınız'
                  }
                >
                  {language.toUpperCase()}
                </div>
              </div>
              <div className="context">
                <h1 title={title}>{title}</h1>
                <p>{description}</p>
                <Filter
                  sortActive={sortActive}
                  sortClick={() => {
                    this.setState({ sortActive: !sortActive }, this.onSort);
                  }}
                  onClickSearch={this.onSearch}
                  onClickClear={() =>
                    this.setState({ filterData: [] }, this.onSearch)
                  }
                />
              </div>
            </div>
          </section>
          <PodcastList
            language={language === 'en' ? 'tr' : 'en'}
            title={title}
            data={
              filterData.length > 0 ? filterData : search?.length > 0 ? [] : data
            }
          />
        </main>
        <Footer />
        <Player />
      </>
    );
  }
}

export default Main;
