import React from 'react';
import { getParameterByName } from '../../utils';
import './styles.scss';

const Footer = () => {
  const playing = getParameterByName('playing');
  const lang = localStorage.getItem('language');
  const en = lang === 'en';
  return (
    <footer className={playing ? 'player' : ''}>
      <p>
        {en
          ? 'We are committed to web accessibility and providing equal access and equal opportunity to individuals with diverse disabilities.'
          : 'Web erişilebilirliğine ve çeşitli engellere sahip bireylere eşit erişim ve fırsat eşitliği sağlamaya kararlıyız.'}
      </p>
      <div className="bottom">
        <p>© Esin Attorney Partnership 2021</p>
        <ul>
          <li>
            <a
              href={
                en
                  ? 'https://www.esin.av.tr/disclaimers/%22%7D'
                  : 'https://www.esin.av.tr/tr/yasal-uyari/'
              }
              target="_blank"
              rel="noreferrer"
            >
              {en ? 'Disclaimers' : 'Yasal Uyarı'}
            </a>
          </li>
          <li>
            <a
              href={
                en
                  ? 'https://www.esin.av.tr/privacy/%22%7D'
                  : 'https://www.esin.av.tr/tr/gizlilik/'
              }
              target="_blank"
              rel="noreferrer"
            >
              {en ? 'Privacy' : 'Gizlilik'}
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
