import React, { useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

const FilterSort = (props) => {
  const { active, onClick } = props;
  const lang = localStorage.getItem('language');
  const en = lang === 'en';
  return (
    <div className="filter-sort">
      <span className="title" title={en ? 'Sorting Type' : 'Sıralama Türü'}>
        {en ? 'Sorting Type' : 'Sıralama Türü'}
      </span>
      <div className="box" onClick={onClick}>
        <b className={!active ? 'active' : ''} aria-label="Eskiden Yeniye Sırala">
          {en ? 'Oldest' : 'En Eski'}
        </b>
        <b className={active ? 'active' : ''} aria-label="Yeniden Eskiye Sırala">
          {en ? 'Latest' : 'En Yeni'}
        </b>
        <span className={`pattern${active ? ' active' : ''}`} />
      </div>
    </div>
  );
};

FilterSort.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FilterSort;
