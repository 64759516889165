import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './styles.scss';
import {
  onCalculateCurrentTime,
  getParameterByName,
  equel,
  getData,
  copy,
} from '../../utils';
import PlayIcon from '../../assets/img/player/play-btn.svg';
import PauseIcon from '../../assets/img/player/pause-btn.svg';
import PrevIcon from '../../assets/img/player/prev-btn.svg';
import NextIcon from '../../assets/img/player/next-btn.svg';
import VolumeIcon from '../../assets/img/player/volume.svg';
import MuteIcon from '../../assets/img/player/mute.svg';
import CreatedByLawpodcast from '../../assets/img/create-by-lawpodcast.png';
import DefaultCover from '../../assets/img/player/music.png';
import ShareIcon from '../../assets/img/share.svg';
import LinkIcon from '../../assets/img/link.svg';
import EmbedIcon from '../../assets/img/embed.svg';

const Player = (props) => {
  const { noFixed } = props;
  const [share, setShare] = useState(false);
  const [play, setPlay] = useState(true);
  const [podcast, setPodcast] = useState(null);

  const onControl = async () => {
    setPodcast(null);
    setPlay(false);
    const lang = getParameterByName('lang');
    const playing = getParameterByName('playing');
    const { data } = await getData(lang);
    const getPodcast = data.find((i) => equel(i.mp3) === playing);
    if (getPodcast) {
      setPodcast(getPodcast);
      setPlay(!noFixed);
    }
  };

  useEffect(() => {
    onControl();
  }, [getParameterByName('playing'), getParameterByName('lang')]);

  if (!podcast) {
    return null;
  }

  const href = location.href;
  const embedHref = href.replace('?', 'embed/?');
  const embed = `<iframe frameborder="0" style="width:100%;height:80px" src="${embedHref}"></iframe>`;
  const lang = localStorage.getItem('language');
  const en = lang === 'en';

  const CustomIcons = {
    play: <img src={PlayIcon} className="img" aria-label={en ? 'Play' : 'Başlat'} />,
    pause: (
      <img src={PauseIcon} className="img" aria-label={en ? 'Pause' : 'Durdur'} />
    ),
    rewind: (
      <img
        src={PrevIcon}
        className="img small"
        aria-label={en ? 'Seek backward' : 'Geri Sarma'}
      />
    ),
    forward: (
      <img
        src={NextIcon}
        className="img small"
        aria-label={en ? 'Seek forward' : 'İleri Sarma'}
      />
    ),
    volume: (
      <img
        src={VolumeIcon}
        className="img small"
        aria-label={en ? 'Volume' : 'Ses'}
      />
    ),
    volumeMute: (
      <img
        src={MuteIcon}
        className="img small"
        aria-label={en ? 'Mute' : 'Sessiz'}
      />
    ),
    previous: '',
    next: '',
    loop: '',
    loopOff: '',
  };

  return (
    <div id="player-wrapper" className={noFixed ? 'no-fixed' : ''}>
      <div className="info">
        <div
          className="cover"
          style={{ backgroundImage: `url(${podcast.poster ?? DefaultCover})` }}
        />
        <div className="content">
          <div className="small-title" aria-label={podcast.mainTitle}>
            {podcast.mainTitle}
          </div>
          <div className="title" aria-label={podcast.title}>
            {podcast.title}
          </div>
        </div>
      </div>
      <div className="player">
        <AudioPlayer
          autoPlay
          src={podcast.mp3}
          layout="horizontal-reverse"
          customAdditionalControls={[
            <>
              {!noFixed && (
                <div className="share" onClick={() => setShare(!share)}>
                  <span>{en ? 'Share' : 'Paylaş'}</span>
                  <img
                    src={ShareIcon}
                    alt="share-icon"
                    onClick={() => setShare(!share)}
                  />
                  <div className={`share-box ${share ? 'active' : ''}`}>
                    <CopyToClipboard text={href} onCopy={copy}>
                      <div
                        className="share-box-content"
                        aria-label={en ? 'Share as Link' : 'Link olarak Paylaş'}
                      >
                        <span>Link</span>
                        <img src={LinkIcon} />
                      </div>
                    </CopyToClipboard>
                    <CopyToClipboard text={embed} onCopy={copy}>
                      <div
                        className="share-box-content"
                        aria-label={
                          en
                            ? 'Share as Embed Code'
                            : 'Yerleştirme Kodu olarak Paylaş'
                        }
                      >
                        <span>{en ? 'Embed Code' : 'Yerleştirme Kodu'}</span>
                        <img src={EmbedIcon} />
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
              )}
            </>,
          ]}
          customIcons={CustomIcons}
        />
      </div>
      <a
        className="created-by"
        title="Lawpodcast"
        href="https://lawpodcast.co/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={CreatedByLawpodcast} alt="created by lawpodcast" />
      </a>
    </div>
  );
};

Player.propTypes = {
  noFixed: PropTypes.bool,
};

export default Player;
