import React, { useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import SearchIcon from '../../assets/img/search.svg';

const FilterSearch = (props) => {
  const { onClick, onClickClear } = props;
  const [search, setSearch] = useState('');
  const lang = localStorage.getItem('language');
  const en = lang === 'en';
  const placeholder = en
    ? 'Enter the keywords you want to search.'
    : 'Aramak istediğiniz anahtar kelimeleri giriniz.';
  return (
    <div className="filter-search">
      <img src={SearchIcon} alt="search-icon" />
      <input
        id="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type="text"
        placeholder={placeholder}
      />
      {search.length > 0 && (
        <div
          className="button clear"
          onClick={() => {
            setSearch('');
            onClickClear();
          }}
          aria-label={en ? 'Clear' : 'Temizle'}
        >
          {en ? 'Clear' : 'Temizle'}
        </div>
      )}
      <div
        className="button"
        onClick={onClick}
        aria-label={en ? 'Click to Search' : 'Arama Yapmak İçin Tıkla'}
      >
        {en ? 'Search' : 'Ara'}
      </div>
    </div>
  );
};

FilterSearch.propTypes = {
  onClick: PropTypes.func,
  onClickClear: PropTypes.func,
};

export default FilterSearch;
