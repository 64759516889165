import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from './services/history';
import './assets/scss/reset.scss';
import './assets/scss/main.scss';
// SCREENS
import Main from './screens/Main';
import Player from './components/Player';

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/embed" component={() => <Player noFixed />} />
        <Route exact path="" component={Main} />
      </Switch>
    </Router>
  );
}

export default App;
