import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import FilterSort from '../FilterSort';
import FilterSearch from '../FilterSearch';

const Filter = (props) => {
  const { sortActive, sortClick, onClickSearch, onClickClear } = props;
  return (
    <div id="filter">
      <FilterSort active={sortActive} onClick={sortClick} />
      <FilterSearch onClick={onClickSearch} onClickClear={onClickClear} />
    </div>
  );
};

Filter.propTypes = {
  sortActive: PropTypes.bool,
  sortClick: PropTypes.func,
  onClickSearch: PropTypes.func,
  onClickClear: PropTypes.func,
};

export default Filter;
