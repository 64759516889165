import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import PodcastListItem from '../PodcastListItem';

const PodcastList = (props) => {
  const { language, title, data } = props;
  const lang = localStorage.getItem('language');
  const en = lang === 'en';
  return (
    <div id="podcast-list">
      <p className="title" title={en ? 'Our Podcasts' : 'Podcastlerimiz'}>
        {en ? 'Our Podcasts' : 'Podcastlerimiz'}
      </p>
      <div className="list">
        <ul>
          {data.map((item, index) => {
            const key = `${JSON.stringify(item)}-${index}`;
            return (
              <PodcastListItem
                key={key}
                language={language}
                mainTitle={title}
                {...item}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

PodcastList.propTypes = {
  language: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
};

export default PodcastList;
